<template>
    <div class="my-appointment">
        <van-sticky>
            <van-tabs @click="onClick" v-model="active" animated>
                <van-tab title="详情"></van-tab>
                <van-tab title="教练团队"></van-tab>
                <van-tab title="骏马图集"></van-tab>
            </van-tabs>
        </van-sticky>
        <div v-if="active === 0" class="my-tabs" >
            <horse-details></horse-details>
        </div>
        <div v-else-if="active === 1" class="my-tabs" >
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="coach-list">
                        <div class="coach-item" v-for="item in list" :key="item.id" @click="goCoach(item.id)">
                            <div class="img left">
                                <van-image class="my-image" fit="cover" :src="getImg(item.picHeadUrl)" alt="">
                                    <template v-slot:error><van-image class="my-image" fit="cover" :src="coachUrl" alt=""/></template>
                                </van-image>
                                <!-- <van-image class="my-image" fit="fill" :src="coachUrl" alt=""/> -->
                            </div>
                            <div class="info right">
                                <div class="name-price">
                                    <div class="name">
                                        {{item.coachName}}
                                        <van-tag :type="item.coachLevel === '01'  ? 'primary' : item.coachLevel === '02' ? 'success' : 'danger'">{{item.coachLevelName}}</van-tag>
                                    </div>
                                    <div class="price" v-if="item.coachPriceVO">
                                        {{item.coachPriceVO.priceOut | spec}}
                                    </div>
                                </div>
                                <div class="desc" v-html="item.professionalProfile"></div>
                            </div>
                        </div>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
            </van-pull-refresh>
        </div>
        <div v-else class="my-tabs" >
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="horse-list">
                        <div class="image" v-for="item in list" :key="item.id">
                            <van-image class="my-image" fit="cover" :src="getImg(item.picUrl)" alt="">
                                <template v-slot:error><van-image class="my-image" fit="cover" :src="horse1" alt=""/></template>
                            </van-image>
                            <div class="name-type">
                                <div class="name">
                                    <span>名称:</span>
                                    <span>{{item.horseName}}</span>
                                </div>
                                <div class="type">
                                    <span>马匹血统:</span>
                                    <span>{{item.horseTypeName}}</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
import HorseDetails from './HorseDetails.vue'
export default {
  components: { HorseDetails },
    mixins: [ mixinPage ],
    data() {
        return { 
            api: '',
            value: '',
            active: 0,
            coachUrl: require('@/assets/img/jiaolian.jpg'),
            horse1: require('@/assets/img/horse1.jpg'),
            levelList: {},
            horseList: {},
            model: {}
        }
    },
    methods: {
        init() {
            this.initList()
        },
        goCoach(id) {
            this.$router.push({path:'/coach', query:{
                id: id
            }})
        },
        onClick() {
            //console.log(this.active)
            this.list = []
            this.showNoMore = false
            this.$set(this.model, 'corpId', this.$route.query.id)
            if(this.active === 0){
                this.showData = false
            }
            if(this.active === 1){
                this.api = 'common/base/coach/coach-listForCorp'
                this.init()
            }else if(this.active === 2){
                this.api = 'common/base/horse/horse-listForCorp'
                this.init()
            }
            
        },
        getLevel() {
            this.$api.post('sys/sysList/sysList-list-byName', {name: '教练级别'}).then(res=>{
                //console.log(res)
                if(res.length !== 0){
                    res.forEach(ele=>{
                        this.levelList[ele.itemValue] = ele.itemText
                    })
                }
                //this.init()
            })
        },
        getHorse() {
            this.$api.post('sys/sysList/sysList-list-byName', {name: '马匹品种'}).then(res=>{
                //console.log(res)
                if(res.length !== 0){
                    res.forEach(ele=>{
                        this.horseList[ele.itemValue] = ele.itemText
                    })
                }
                //this.init()
            })
        }

    },
    filters: {
        spec(val) {
            return val+'元/鞍时'
        }
    },
    mounted() {
        if(this.active === 1){
            this.$set(this.model, 'corpId', this.$route.query.id)
            this.api = 'member/base/coach/coach-listForCorp'
            this.init()
        }
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-appointment{
        .van-tabs__line{
            background-color: $main;
            width: 80px;
        }
        .my-tabs{
            .van-tabs__line{
                width: 80px;
                height: 2px;
                background-color: $main;
                border-radius: 3px;
            }
            .coach-list{
                padding: 0 10px;
                margin-top: 10px;
                .coach-item{
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 20px;
                    border-radius: 9px;
                    padding: 10px;
                    box-shadow: 0 0 9px 6px $boxShadow;
                    background-color: #fff;
                    .left{
                        .my-image{
                            width: 100px;
                            height: 100px;
                        }
                    }
                   
                    .right{ 
                        padding: 5px 0 5px 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        .desc{
                            display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp:2;/**显示的行数**/
                            overflow:hidden;/**隐藏超出的内容**/
                            color: $gray;
                            margin-top: 20px;
                        }
                        .name-price{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-right: 10px;
                            .name{
                                font-size: 14px;
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
            .my-pull-refresh{
                padding: 10px;
                .horse-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    //background-color: #fff;
                    .image{
                        margin-bottom: 15px;
                        box-shadow: 0 0 9px 6px $boxShadow;
                        background-color: #fff;
                        .my-image{
                            height: 100px;
                            width: 170px;
                        }
                        .name-type{
                            //text-align: center;
                            padding: 5px 10px 10px;
                            display: flex;
                            justify-content: space-between;
                            .name, .type{
                                font-size: 12px;
                            }
                        }
                    }
                    
                }
            }
        }
    }
</style>