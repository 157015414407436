<template>
    <div class="horse-detalis">
        <!-- 头部 -->
        <div class="horse-detalis__header">
            <van-swipe @change="onChange">
                <van-swipe-item v-for="(image, index) in picList" :key="index" >
                    <van-image class="my-image" lazy-load fit="cover" :src="getImg(image.url)" alt="">
                        <template v-slot:error><van-image lazy-load class="my-image" fit="cover" :src="imgUrl" alt=""/></template>
                    </van-image>
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">{{ current + 1 }}/{{picList.length}}</div>
                </template>
            </van-swipe>
        </div>
        <div class="tel-address">
            <div class="tel" @click="callPhone">
                <van-icon name="phone-o" />联系电话
            </div>
            <div class="address" @click="openActive">
                校区地址<van-icon name="location-o" />
            </div>
        </div>
        <!-- 简介 -->
        <div class="horse-desc">
            <div class="title">
                经营介绍
            </div>
            <div class="text">
                {{information.businessIntroduction}}
            </div>
        </div>
        <!-- 简介 -->
        <div class="horse-desc">
            <div class="title">
                场地介绍
            </div>
            <div class="text">
                {{information.siteIntroduction}}
            </div>
        </div>
        <van-sticky v-if="$help.storeTemp.get('personType') === 0">
            <van-cell title="反馈列表" />
        </van-sticky>
        <!-- 反馈列表 -->
        <van-list
            v-model="loading"
            :finished="finished"
            @load="onLoad1"
            class="feed-list"
            v-if="$help.storeTemp.get('personType') === 0"
            >
            <div class="item" v-for="item in list" :key="item.id" :class="{'slef': item.memberCode === memberCode}">
                <div class="avater">
                    <van-image class="avater-image" round fit="cover" :src="getImg(item.picHeadUrl)" alt="">
                        <template v-slot:error><van-image  roundclass="avater-image" fit="cover" :src="avatar" alt=""/></template>
                    </van-image>
                </div>
                <div class="name-content">
                    <div class="name">
                        {{item.memberName || '匿名'}}
                    </div>
                    <div class="content">
                        {{item.feedback}}
                    </div>
                </div>
                <div v-if="item.memberCode === memberCode" class="del" @click="deleteFeed(item)">
                    删除
                </div>
            </div>
            <no-more v-show="showNoMore && !loading"/>
            <!-- 暂无数据 -->
            <no-data v-if="showData"/>
        </van-list>
        <van-field
            class="my-field"
            v-model="feedback"
            center
            clearable
            type="textarea"
            placeholder="请输入反馈信息"
            v-if="this.$help.storeTemp.get('personType') === 0"
            >
            <template #button>
                <van-button size="small" type="primary" @click="onSubmit">反馈</van-button>
            </template>
        </van-field>

        <div id="mymap"></div>

        <van-action-sheet v-model="showActive" :actions="actions" @select="onSelect" close-on-click-action cancel-text="取消" />

       
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'common/bill/feedback/feedback-list',
            imgUrl: require('@/assets/img/horse1.jpg'),
            avatar: require('@/assets/img/avatar.jpeg'),
            feedback: '',
            information: {},
            memberCode: '',
            model: {},
            picList: [],
            actions: [
                { name: '高德地图' },
                { name: '百度地图' },
                { name: '腾讯地图' }
            ],
            //定义终点位置
            EndXY: {
                lng: '',
                lat: '',
                city: "南昌市",
            },
            map: null,
            center: [115.89, 28.68],
            zoom: 15.5,
            AMap: {},
            showActive: false,
            current : 0
        }
    },
    methods: {
        init() {
            this.initList()
        },
        getDetails() {
            this.$help.loading()
            this.$api.post('common/base/corp/corp-show', this.$route.query).then(res=>{
                // console.log(res)
                this.information = res
                if(res.picUrl){
                    res.picUrl.split(',').forEach(ele=>{
                        this.picList.push({
                            url: ele
                        })
                    })
                }
                this.EndXY.lng = this.information.localLongitude
                this.EndXY.lat = this.information.localLatitude
            })
            .finally(this.$help.loaded)
        },
        //联系
        callPhone() {
            window.location.href = 'tel://' + this.information.phone;
        },
        //
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then(() => {
                this.memberCode = this.$store.state.memberUser.memberCode
            })
        },
        getCoachUser() { 
            this.$store.dispatch('getCoachUser').then(() => {
                this.memberCode = this.$store.state.coachUser.coachCode
            })
        },
        deleteFeed(item){
             this.$help.confirm({
                title: '提示',
                message: '确定删除吗',
            })
            .then(() => {
                this.$help.loading()
                this.$api.post('member/bill/feedback/feedback-delete', {id: item.id}).then(res=>{
                    if(res.data){
                        this.$help.success('删除成功!')
                        this.init()
                    }else{
                        this.$help.error(res.msg) 
                    }
                })
                .finally(this.$help.loaded)
            })
            .catch(() => {})
        },
        onSubmit() {
            this.$help.loading()
            this.$api.post('member/bill/feedback/feedback-save', {corpId: this.$route.query.id, feedback: this.feedback}).then(res=>{
                if(res.data){
                    this.$help.success('反馈成功')
                    this.init()
                }else{
                    this.$help.error(res.msg)
                }
            })
            .finally(()=>{
                this.$help.loaded()
                this.feedback = ''
            })
        },
        openActive() {
            this.showActive = true
        },
        onSelect(data) {
            if(data.name === '高德地图'){
                this.handlClick(1)
            }else if(data.name === '百度地图'){
                this.handlClick(2)
            }else if(data.name === '腾讯地图'){
                this.handlClick(3)
            }
        },
        handlClick(val) {
            /* Start  判断手机是IOS还是安卓 */
            let u = navigator.userAgent;
            //判断是否安卓
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            // 判断是否IOS
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            /* End */
            //判断是否点击高德地图
            if(val === 1) {
                //this.$help.loading() 
                this.$help.loading()
                setTimeout(() => {
                    if (!(document.hidden || document.webkitHidden)) {
                        //debugger
                        window.location.href = `https://uri.amap.com/marker?position=${this.EndXY.lng},${this.EndXY.lat}`;
                        this.$help.loaded()
                    }
                }, 1000);
            //百度地图
            }else if(val === 2){
                // let queryStr = `?origin=南昌市&destination=${this.EndXY.lat},${this.EndXY.lng}&region=${this.EndXY.city}&coord_type=bd09ll&mode=driving`
                let lat = this.$help.tobdMap(this.EndXY.lat, this.EndXY.lng)[0]
                let lng = this.$help.tobdMap(this.EndXY.lat, this.EndXY.lng)[1]
                if(isAndroid) {
                    window.location.href = `http://api.map.baidu.com/marker?location=${lat},${lng}&title=校区位置&output=html&src=webapp.baidu.openAPIdemo`
                }else if(isIOS) {
                    window.location.href = `http://api.map.baidu.com/marker?location=${lat},${lng}&title=校区位置&output=html&src=webapp.baidu.openAPIdemo`
                }
            //腾讯地图
            }else if(val === 3){
                //console.log('腾讯地图')
                window.location.href = `https://apis.map.qq.com/uri/v1/geocoder?coord=${this.EndXY.lat},${this.EndXY.lng}`

            }
        },
        onChange(index) {
            this.current = index;
        },

    },
    mounted() { 
        this.$set(this.model, 'corpId', this.$route.query.id)
        this.init()
        this.getDetails()
        //this.getMemberUser()
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.getMemberUser()
        }
        if(this.$help.storeTemp.get('personType') === 1) {
            this.getCoachUser()
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .horse-detalis{
        &__header{
            position: relative;
            .my-image{
                width: 100%;
                height: 150px;
            }
            .van-swipe__indicator--active{
                background-color: #fff !important;
            }
            .custom-indicator {
                position: absolute;
                right: 5px;
                bottom: 5px;
                padding: 2px 5px;
                font-size: 12px;
                background: rgba(255, 255, 255, .5);
                color: #fff;
            }

        }
        #mymap{
            display: none;
        }
        .tel-address{
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            background-color: #fff;
            color: $main;
            .tel, .address{
                font-size: 16px;
                flex: 1;
                text-align: center;
            }
            .van-icon{
                font-size: 16px;
                padding: 0
            }
        }
        .horse-desc{
            margin: 20px 15px;
            box-shadow: 0 0 9px 6px $boxShadow;
            padding: 10px;
            border-radius: 9px;
            background-color: #fff;
            .title{
                font-weight: bold;
                padding-bottom: 10px;
            }
        }
        .my-field{
            position: fixed;
            bottom: 0;
            z-index: 1000;
            .van-field__button{
                .van-button--primary{
                    background-color: $main;
                    border: 0;
                    padding: 0 15px;
                }
            }
        }
        .feed-list{
            padding: 0 20px 80px 20px;
            margin-top: 20px;
            position: relative;
            min-height: 200px;
            .no-data{
                position: absolute;
                top: 30%;
            }
            .slef{
                padding: 10px 10px 25px 10px !important;
            }
            .item{
                display: flex;
                margin-bottom: 20px;
                box-shadow: 0 0 9px 6px $boxShadow;
                border-radius: 9px;
                padding: 10px;
                position: relative;
                .del{
                    width: 30px;
                    position: absolute;
                    bottom: 5px;
                    right: 10px;
                    color:  $red;
                }
                .name-content{
                    margin-top: 5px;
                    margin-left: 10px;
                    .name{
                        color: $gray;
                    }
                    .content{
                        background-color: #fff;
                        padding: 5px;
                        border-radius: 12px;
                    }
                }
                .avater {
                    &-image{
                        height: 50px;
                        width: 50px;
                        img{
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        .van-cell{
            padding: 15px 20px;
        }
    }

</style>